<template>
  <v-container class="welcome-news d-flex flex-column align-center">
    <v-btn class="mb-4 align-self-start" @click="$router.push({name: 'PlaneteOnlineNews'})">
      {{ $t("actions.back") }}
    </v-btn>
    <v-card width="840" class="card-news">

      <v-card-title class="d-flex justify-center">
        Aujourd'hui s'ouvre une nouvelle page de notre histoire
      </v-card-title>
      <v-img height="360" :src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-1680x960.webp')" alt=""></v-img>


      <div class="over-image ml-12">
        <p class="title white--text visible-on-colors">Nos solutions planete-online</p>
        <p class="subtitle white--text visible-on-colors">Plus qu’une solution complète de planification, un vrai outil d’aide à la décision.</p>
        <v-btn color="primary" @click="window.open('https://www.planete-online.fr/nos-solutions', '_blank')">En savoir plus</v-btn>
      </div>
      <v-card-text class="pa-12">
        <div class="text-justify">
          Chers Clients,
          <br>
          <br>
          Depuis sa création, notre logiciel PL@N.net n’a pas cessé d’évoluer et nous tenons à vous remercier pour votre
          participation active dans cette belle aventure.
          <br>
          <br>
          Au fil des années, nous avons pris conscience qu’il n’y avait pas de frontière géographique à l’utilisation de
          nos produits et services et qu’il n’y avait pas de secteur professionnel, dans lequel nous ne pourrions
          évoluer.
          <br>
          <br>
          Forts de ces constats, il devenait essentiel de faire évoluer notre communication.
          <br>
          <br>
          <b>Nous sommes donc fiers de vous annoncer la naissance de <span class="primary--text">planete-online</span>,
            qui tout en reprenant l’ADN de PL@N.net, va nous permettre d’entreprendre une nouvelle phase importante de
            la vie de notre entreprise.</b>
          <br>
          <br>

          <div class="d-flex flex-row flex-nowrap align-center justify-space-around my-8">
            <p>
              A cette <span class="primary--text font-weight-bold">nouvelle marque</span>, s’associe un <span
                class="primary--text font-weight-bold">nouveau logo</span>
            </p>
            <img width="256" :src="require('@/Assets/Images/Logo/logo-planete-online.svg')" alt="" class="float-right">
          </div>

          <p class="text-justify">
            et de manière plus générale, une nouvelle communication articulée, autour des objectifs que nous nous sommes
            fixés, avec beaucoup d’ambition, mais également beaucoup de sérénité acquise, au fil de note collaboration.
            <br>
            <br>
            Nous ne manquerons pas de vous faire découvrir cette nouvelle stratégie, au fur et à mesure qu’elle se
            mettra en place, mais nous tenons avant tout, à vous assurer de notre totale mobilisation, pour continuer à
            vous apporter les services auxquels vous êtes attachés.
            <br>
            <br>
            Les crises sont révélatrices des forces et des faiblesses des entreprises, celle que nous traversons encore,
            en est un exemple plus actuel que jamais. Nous sommes prêts, pour relever les défis qui nous attendent, dont
            certains sont à notre propre initiative, afin d’être à vos côtés, plus solides et plus innovants que jamais.
            <br>
            <br>
            Cette nouvelle phase de la vie de notre entreprise, sans conséquence sur vos accès et vos connexions, vous
            confortera plus encore dans le support qualitatif que nous avons toujours été attentifs à vous offrir, en y
            ajoutant le bénéfice des dernières technologies disponibles sur le marché.
          </p>

          <div class="d-flex flex-column align-center text-center justify-center primary white--text pa-4 rounded">Les changements de Marque, de Logo, de Page d'accueil, de Site Internet et de blocs de signature sont fixés au :
            <br>
            <span class="title mt-4">15 février 2021</span>
          </div>

          <div class="d-flex flex-column justify-center align-center mt-8">
            Découvrez notre offre et nos solutions de planification, de formation, et d’aide à la décision sur :
            <v-btn text @click="openSite">www.planete-online.fr</v-btn>
          </div>

          <div class="d-flex flex-column align-center mt-8">
            Retrouvez nous sur :
            <img :src="require('@/Components/Views/Home/Widgets/WidgetPlaneteOnlineNews/welcome-news-linkedin.png')" alt="" @click="openLinkedin" class="rounded hover-cursor mt-4">
            <a href="http://fr.linkedin.com/company/GTP-Conseil" target="_blank" class="blue-grey--text mt-2">fr.linkedin.com/company/GTP-Conseil</a>

          </div>



        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "WelcomeNews",
  data() {
    return {
      window,
    }
  },
  methods: {
    openSite: function () {
      window.open('http://www.planete-online.fr', '_blank')
    },
    openLinkedin: function () {
      window.open('http://fr.linkedin.com/company/GTP-Conseil', '_blank')
    }
  }
}
</script>

<style scoped>
.welcome-news {
  width: 75%;
}

.hover-cursor {
  cursor: pointer;
}

.over-image {
  position: absolute;
  top: 16em;
}

.card-news {
  position: relative;
}

.visible-on-colors {
  text-shadow:
    /*0 0 3px rgba(0,0,0,1)*/
      -1px -1px 3px rgba(0, 0, 0, .35),
      1px -1px 3px rgba(0, 0, 0, .35),
      -1px 1px 3px rgba(0, 0, 0, .35),
      1px 1px 3px rgba(0, 0, 0, .35)
;
}
</style>